//
// Page - Error 1
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.kt-error-v1 {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    .kt-error-v1__container {
        .kt-error-v1__number {
            font-size: 120px;
            margin-left: 80px;
            margin-top: 4rem;
            font-weight: 700;
            color: kt-base-color(label, 3);
        }

        .kt-error-v1__desc {
            font-size:  1.5rem;
            margin-left: 80px;
            color: kt-base-color(label, 2);
        }
    }
}

@include kt-mobile() {
    .kt-error-v1 {
        .kt-error-v1__container {
            .kt-error-v1__number {
                margin: 120px 0 0 3rem;
                font-size: 8rem;
            }

            .kt-error-v1__desc {
                margin-left: 3rem;
                padding-right: 0.5rem;
            }
        }
    }
}
