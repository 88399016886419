* {
  font-family: 'Onest', Helvetica, sans-serif;
}

body {
  background: #f5f8fa !important;
}

// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-image-gallery/styles/css/image-gallery.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

// for images gallery (in product view page)

:root {
  --fc-button-bg-color: #470D63FF;
  --fc-button-hover-bg-color: #470D63FF;
  --fc-button-border-color: #470D63FF;
  --fc-button-hover-border-color: #470D63FF;
  --fc-button-active-bg-color: #470D63FF;
  --fc-button-active-border-color: #470D63FF;
  --fc-bg-event: #470D63FF;
}

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}
// .fc-event {
  // background-color: #369ff7;
  // border-color: #369ff7;
  // color: white;
// }

.image-gallery-thumbnails {
  padding: 0;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.image-gallery-thumbnail {
  border-radius: none;
  width: 100px !important;
  height: 100px !important;
  border: 0px solid transparent;
  margin-right: 15px;


  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
  }

  .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }
}

.image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image-gallery-thumbnail-inner {
  width: 100px !important;
  height: 100px !important;
}

.image-gallery-thumbnail-inner img {
  object-fit: cover;
  width: 100px !important;
  height: 100px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: none;
}

.image-gallery-slide-wrapper {
  position: relative;

  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 200px); // 100px + 10px for margin

    @media (max-width: $ig-small-screen) {
      width: calc(100% - 87px); // 81px + 6px for margin
    }
  }

  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }

  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }

  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 100px;

    @media (max-width: $ig-small-screen) {
      width: 81px; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        +.image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 6px;
        }
      }

    }
  }

  &.left,
  &.right {
    margin: 0 45px;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}

// fix material ui icons in safari
.MuiSvgIcon-root {
  transform: scale(1);
}

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}

.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

@media screen and (max-width: 500px) {
  .MuiTablePagination-root {
    width: 100%;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiTablePagination-spacer,
  .MuiTablePagination-spacer+.MuiTablePagination-caption {
    display: none;
  }

  .MuiTablePagination-select {
    padding-left: 0 !important;
  }

  .MuiTablePagination-toolbar,
  .MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiTablePagination-selectRoot {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}

// CkEditor
.ck-editor__editable {
  min-height: 400px;
}

//Redesign

.kt-menu-cart-icon {
  color: #470D63FF !important;
}


.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text {
  color: #470D63FF !important;
}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
  color: #000000 !important;
}

.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item:hover>.kt-menu__link .kt-menu__link-text {
  color: #470D63FF !important;
}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text,
.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text {
  color: #470D63FF !important;
}

.kt-menu__link {


  .kt-menu__link-text {
    color: #470D63FF !important;
  }
}
